import React from 'react';
//import MuiLink from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { Link as GatsbyLink } from 'gatsby';

// const LinkButton = React.forwardRef(function Link(props, ref) {
//   return <MuiLink component={GatsbyLink} ref={ref} {...props} />;
// });

const LinkButton = React.forwardRef(function Link(props, ref) {
    return <Button component={GatsbyLink} ref={ref} {...props} />;
  });
  

export default LinkButton;