import React from "react"
import Button from '@material-ui/core/Button';
import styles from "./navbar.module.scss"
import { Link } from "gatsby"

import MuiLink from '@material-ui/core/Link';
import LinkButton from './buttonLink';

const NavBar = () => {


    return (
        <nav className={styles.container}>
            <Link to="/" title="Home" className={styles.homebtn}>
                <svg xmlns="http://www.w3.org/2000/svg" width="115.688" height="49.988" viewBox="0 0 30.609 13.226" fill="#002f4e"><path d="M4.036 7.37l-.563.568L0 5.038l3.473-2.9.563.574L1.22 5.028zm8.982 2.37c-.856.253-1.804.64-2.705-.097-.362-.327-.53-1.052-.56-1.665-.083.014-.164.022-.243.026l-.222.01a3.92 3.92 0 0 1-1.478-.269c-.44-.18-.816-.44-1.127-.78s-.55-.76-.718-1.256-.248-1.063-.248-1.7c0-.627.083-1.2.248-1.685a3.59 3.59 0 0 1 .723-1.282A2.97 2.97 0 0 1 7.814.269C8.265.1 8.756 0 9.287 0c.55 0 1.046.1 1.483.274a3.14 3.14 0 0 1 1.12.77 3.47 3.47 0 0 1 .718 1.266c.165.5.253 1.066.253 1.7 0 .94-.193 1.733-.58 2.377s-.897 1.096-1.545 1.354c.014.393.107.698.28.915s.486.326.94.326a2.24 2.24 0 0 0 .5-.067l.42-.114h.14zM11.798 4c0-1-.224-1.77-.672-2.3S10.067.884 9.292.884c-.782 0-1.397.272-1.845.816S6.78 3 6.78 4c0 1 .227 1.783.682 2.32s1.065.8 1.83.8 1.373-.267 1.824-.8.682-1.3.682-2.32zm8.422-2.956q.47.517.718 1.266.253.75.253 1.7 0 .95-.258 1.705-.253.75-.713 1.25-.475.522-1.127.785-.646.264-1.478.264-.8 0-1.478-.27-.66-.27-1.127-.78-.465-.512-.718-1.256-.248-.744-.248-1.7 0-.94.248-1.685.248-.75.723-1.282.455-.506 1.127-.775Q16.82 0 17.617 0q.827 0 1.483.274.66.27 1.12.77zM20.128 4q0-1.5-.672-2.3-.672-.816-1.835-.816-1.173 0-1.845.816-.667.8-.667 2.3 0 1.514.682 2.32.682.8 1.83.8 1.147 0 1.824-.8.682-.806.682-2.32zM26 .553l-3.545 8.413h-.858L25.13.553zm.588 2.16l.558-.574 3.473 2.9-3.473 2.9-.558-.568 2.816-2.32z" /><g fill="none" stroke="#002f4e"><path d="M8.92 10.475s4.912 5.894 10.086-1.3" strokeWidth=".965" /><path d="M8.488 5.294c.953-.106 1.706-.006 1.706-.006" strokeWidth="1.23" /><path d="M16.85 5.302c.874-.108 1.564-.006 1.564-.006" strokeWidth="1.188" /></g></svg>
            </Link>

            <div className={styles.pages}>

                <LinkButton color="primary" to="/projects/" activeStyle={{ backgroundColor: "#dfcfae" }}>Projects</LinkButton>

                {/* 
                <Link to="/projects/" activeClassName={styles.active} color="primary" component="button">
                    <Button color="primary">Projects</Button>
                </Link> */}

                <Button href="https://www.linkedin.com/in/quintinorsmond/" color="primary">Resume</Button>

                {/* <a href="https://www.linkedin.com/in/quintinorsmond/">Resume</a> */}
                {/* <MuiLink color="primary" href="https://www.linkedin.com/in/quintinorsmond/" component="button">
                    Resume
                </MuiLink> */}

                <LinkButton color="primary" to="/contact/" activeStyle={{ backgroundColor: "#dfcfae" }}>Contact</LinkButton>


                {/* <Link to="/contact" activeClassName={styles.active} color="primary">
                    <Button href="#text-buttons" color="primary">Contact</Button>
                </Link> */}
            </div>

        </nav>
    );
};

export default NavBar;