import React from "react"
import styles from "./trimmed-container.module.css"


const TrimmedContainer = ({ children }) => {


    return (
        <section className={styles.container}>{children}</section>
    );
};

export default TrimmedContainer;