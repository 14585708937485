import React from "react"
import styles from "./footer.module.scss"
import TrimmedContainer from "./trimmed-container";
import Stackoverflow from "../assets/svgs/so-icon.inline.svg";
import Octicon from "../assets/svgs/octicon.inline.svg";
import Linkedin from "../assets/svgs/linkedin.inline.svg"

const Footer = () => {


    return (
        <footer className={styles.container}>

            <TrimmedContainer>
                <div className="flexed">
                    <div>
                        Copyright © {new Date().getFullYear()} Quintin Orsmond
                    </div>
                    <span className="spacer"></span>
                    <div className={styles.logos}>

                        <a href="https://stackoverflow.com/users/264394/qorsmond">
                            <Stackoverflow />
                        </a>
                        <a href="https://github.com/qorsmond">
                            <Octicon />
                        </a>
                        <a href="https://www.linkedin.com/in/quintinorsmond">
                            <Linkedin />
                        </a>
                    </div>
                </div>
            </TrimmedContainer >

        </footer >
    );
};

export default Footer;